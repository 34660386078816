import React, { useState } from "react";
import { getDatabase, ref, set } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid"; // Pour générer des codes uniques

const OrganisateurForm = () => {
  const [email, setEmail] = useState("");
  const [trailName, setTrailName] = useState("");
  const [gpxFile, setGpxFile] = useState(null);
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email || !trailName || !gpxFile) {
      setStatus("Veuillez remplir tous les champs avant de soumettre.");
      return;
    }

    try {
      const db = getDatabase();
      const storage = getStorage();

      // Génération d'un code unique basé sur le nom du trail
      const trailCode = trailName.toUpperCase().replace(/\s+/g, "_") + "_" + uuidv4().slice(0, 6);

      // Upload du fichier GPX dans Firebase Storage
      const storagePath = `trails/${trailCode}.gpx`;
      const fileRef = storageRef(storage, storagePath);
      await uploadBytes(fileRef, gpxFile);

      // Sauvegarde des métadonnées dans Firebase Realtime Database
      const trailRef = ref(db, `trails/${trailCode}`);
      await set(trailRef, {
        email,
        trailName,
        gpxPath: storagePath,
        code: trailCode,
      });

      setStatus(`Trail créé avec succès ! Code : ${trailCode}`);
    } catch (error) {
      console.error("Erreur lors de la création du trail :", error);
      setStatus("Une erreur s'est produite. Veuillez réessayer.");
    }
  };

  return (
    <div style={{ maxWidth: "600px", margin: "0 auto" }}>
      <h2>Créer un Trail</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email de l'organisateur :</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Nom du Trail :</label>
          <input
            type="text"
            value={trailName}
            onChange={(e) => setTrailName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Fichier GPX :</label>
          <input
            type="file"
            accept=".gpx"
            onChange={(e) => setGpxFile(e.target.files[0])}
            required
          />
        </div>
        <button type="submit">Créer le Trail</button>
      </form>
      {status && <p>{status}</p>}
    </div>
  );
};

export default OrganisateurForm;
