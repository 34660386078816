// src/firebase.js
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, onValue } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAKkT4-3D-FRy0mQV7lYd-EolDFgWU-Y",
  authDomain: "trail-tracker-3ff27.firebaseapp.com",
  projectId: "trail-tracker-3ff27",
  storageBucket: "trail-tracker-3ff27.appspot.com",
  messagingSenderId: "225985859760",
  appId: "1:225985859760:web:b072c3e09fd4ce9a8f6b75",
  measurementId: "G-BDXG5E6BNG",
  databaseURL: "https://trail-tracker-3ff27-default-rtdb.europe-west1.firebasedatabase.app",
};

// Fonction pour récupérer le tracé GPX d'un utilisateur
export const fetchTrail = (userId, callback) => {
    const db = getDatabase();
    const trailRef = ref(db, `tracks/${userId}`); // Chemin vers les tracés de l'utilisateur
    
    get(trailRef)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const trailData = snapshot.val(); // Récupérer les données du tracé
          console.log("Données du tracé récupérées:", trailData); // Afficher pour déboguer
          
          const coordinates = [];
  
          // Traiter chaque point de tracé
          for (const key in trailData) {
            const point = trailData[key];
            
            // Vérifier que latitude et longitude existent avant de les ajouter
            if (point.latitude && point.longitude) {
              coordinates.push({ lat: point.latitude, lng: point.longitude });
            } else {
              console.warn(`Point invalide trouvé : ${key}, latitude: ${point.latitude}, longitude: ${point.longitude}`);
            }
          }
  
          console.log("Coordonnées après traitement : ", coordinates); // Afficher les coordonnées avant de les passer au callback
          callback(coordinates); // Passer les coordonnées à la fonction callback
        } else {
          console.log("Pas de tracé trouvé pour cet utilisateur");
          callback([]); // Retourner un tableau vide si aucun tracé n'est trouvé
        }
      })
      .catch((error) => {
        console.error("Erreur lors de la récupération du tracé GPX: ", error);
        callback([]); // Retourner un tableau vide en cas d'erreur
      });
  };


const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const fetchPosition = (userId, callback) => {
  const positionRef = ref(database, `positions/${userId}`);
  onValue(positionRef, (snapshot) => {
    callback(snapshot.val());
  });
};
