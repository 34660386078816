import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Polyline } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { fetchPosition, fetchTrail } from "./firebase";
import OrganisateurForm from "./pages/OrganisateurForm";

// Remplacer l'icône par défaut de Leaflet
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconUrl: markerIcon,
  iconRetinaUrl: markerIcon2x,
  shadowUrl: markerShadow,
});

const MapPage = () => {
  const [userPosition, setUserPosition] = useState(null);
  const [trailCoordinates, setTrailCoordinates] = useState([]);
  const [showMarker, setShowMarker] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    const userId = "demo_user"; // Remplace par une logique pour obtenir userId
    if (userId) {
      fetchPosition(userId, (position) => {
        if (position) {
          setUserPosition({ lat: position.latitude, lng: position.longitude });
          setShowMarker(true);
        } else {
          setUserPosition({ lat: 48.858844, lng: 2.294351 }); // Paris
          setShowMarker(false);
        }
      });

      fetchTrail(userId, (trailData) => {
        if (trailData) {
          setTrailCoordinates(trailData);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (userPosition && mapRef.current) {
      mapRef.current.setView(userPosition, 13);
    }
  }, [userPosition]);

  return (
    <div style={{ height: "100vh" }}>
      <MapContainer
        ref={mapRef}
        center={userPosition || { lat: 48.858844, lng: 2.294351 }}
        zoom={13}
        style={{ height: "100%", width: "100%" }}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {trailCoordinates.length > 0 && <Polyline positions={trailCoordinates} color="blue" />}
        {showMarker && userPosition && <Marker position={userPosition} />}
      </MapContainer>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MapPage />} />
        <Route path="/organisateur" element={<OrganisateurForm />} />
      </Routes>
    </Router>
  );
};

export default App;
